import { InjectionToken, Signal } from '@angular/core';
import { ISettings } from '@core/models/settings';
import { LoadingStatus } from '@core/types/loading-status';
import { Observable } from 'rxjs';

export const settingsServiceToken: InjectionToken<ISettingsService> = new InjectionToken<ISettingsService>('settingsServiceToken');

export interface ISettingsService {
    settings: Signal<ISettings>;

    settings$: Observable<ISettings>;

    loadingStatus: Signal<LoadingStatus>;

    updateSettings(partialSettings: Partial<ISettings>): void;
}
